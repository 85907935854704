import '../styles/homepage/cle-full-page-banner.css'
import { register, SwiperContainer } from 'swiper/element/bundle'
import { SwiperOptions } from 'swiper/types'
import '../scripts/homepage/carousel.ts'

document.addEventListener('DOMContentLoaded', function () {
  register()
  const mainBannerSwiperEl = document.querySelector('swiper-container') as SwiperContainer

  const params: SwiperOptions = {
    injectStyles: [`
       :host {
        --swiper-navigation-size: 8px;
        --swiper-navigation-color: #fff;
      }
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        padding: 1rem;
        width: auto;
        right: 0;
        left: unset;
        bottom: 0;
        }
        
        @media (min-width: 640px) {
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          padding: 1.4rem 3rem;
        }
      }
      .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet:not(:last-child) {
        margin: 0 10px;
        padding: 0;
      }
      .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
        margin: 0 0 0 10px;
      }
      .swiper-pagination-bullet {
        border-width: 1px;
        border-color: var(--swiper-navigation-color);
        border-style: solid;
        border-radius: 0;
        color: var(--swiper-navigation-color);
        background: transparent;
        opacity: 100%;
        width: var(--swiper-navigation-size);
        height: var(--swiper-navigation-size);
      }
      .swiper-pagination-bullet-active {
        background: var(--swiper-navigation-color);
      }
    `],
    pagination: {
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
    speed: 1500,
    loop: true,
    slidesPerView: 1
  }

  Object.assign(mainBannerSwiperEl, params)

  mainBannerSwiperEl.addEventListener('swiperresize', (event) => {
    const [ swiper ] = (event as CustomEvent).detail;
    if (swiper.width < 768) {
      swiper.navigation.disable();
    } else {
      swiper.navigation.enable();
    }
  });

  mainBannerSwiperEl.initialize();
});
